@import "~element-ui/packages/theme-chalk/src/common/var";
@import url('https://fonts.googleapis.com/css?family="Helvetica Neue":400,600');


/* icon font path, required */
$--font-path: '~element-ui/lib/theme-chalk/fonts';

// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: "Helvetica Neue", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$color-primary: $blue; //#18c79c
$--color-text-regular: #2d2b2b;
$--color-text-secondary: #525454;

$--xs: 480px !default;
